import { ref, unref, watch } from "@vue/composition-api";
import { required } from "@/components/GlobalComponents/FormComponents/Validator/rules";

export default function useValidator(
    props,
    validator = null,
    { getValue = (props) => props.value } = {}
) {
    const hasError = ref(false);
    const errorMessage = ref("");

    watch(
        () => props.value,
        () => {
            hasError.value = false;
        }
    );

    function validate() {
        let isValid = true;
        let result = true;
        if (unref(props.required)) {
            result = required(unref(getValue(props)));
        }
        if (validator !== null) {
            result = validator(unref(getValue(props)));
        }
        if (result === true) {
            isValid = true;
        } else {
            hasError.value = true;
            errorMessage.value = result;
            isValid = false;
        }

        return isValid;
    }

    return { validate, hasError, errorMessage };
}
