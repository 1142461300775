<template>
  <div
    class="project-unit-lawyer-unit-assigned fd-validation"
    :class="{ error: hasError }"
  >
    <div class="mb-2">
      {{ lawyerType.getType().full }}
    </div>

    <div>
      <p v-if="_.isEmpty(value)" class="hint bordered-card p-2">
        Please assign an {{ lawyerType.getType().abbr.toUpperCase() }} lawyer
      </p>
      <div v-else>
        <lawyer :lawyer="value" :isLoading="isLoading"></lawyer>
      </div>
    </div>
  </div>
</template>

<script>
import LawyerType from "@/modules/Project/classes/LawyerType";
import useValidator from "@/modules/General/composables/useValidator";
import { reactive } from "@vue/composition-api";
import { isEmpty } from "lodash";

export default {
  setup(props) {
    function validateLawyer(val) {
      return !isEmpty(val) || "this field is required";
    }
    const { validate, hasError, errorMessage } = useValidator(
      props,
      validateLawyer
    );
    const lawyerType = reactive(new LawyerType(props.type));

    return {
      // Validator
      validate,
      hasError,
      errorMessage,

      lawyerType
    };
  },
  components: {
    Lawyer: () => import("./Lawyer")
  },
  mixins: [],
  props: {
    value: {
      required: true,
      default: () => ({})
    },
    type: {
      type: String,
      validator: (val) => new LawyerType.validateType(val)
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.project-unit-lawyer-unit-assigned {
  &.error .hint {
    border: solid 1px $color-danger;
    color: $color-danger;
  }
}
</style>
