export default class LawyerType {
    type;

    static TYPES = {
        LA: {
            abbr: "la",
            full: "Loan Application"
        },
        SPA: {
            abbr: "spa",
            full: "Sales and Purchase Agreement"
        }
    };

    constructor(type) {
        if (!LawyerType.validateType(type)) {
            LawyerType.throwError(
                `${type} is not a lawyer type. use getTypes() to get the lawyer types.`
            );
        }
        let types = Object.keys(LawyerType.TYPES).map(
            (key) => LawyerType.TYPES[key]
        );
        this.type = types.find((t) => t.abbr == type);
    }

    setType(type) {
        this.type = Object.entries(LawyerType.TYPES).find(
            (t) => t.abbr == type
        );
    }

    getType() {
        return this.type;
    }

    static getTypes() {
        return [LawyerType.TYPES.LA.abbr, LawyerType.TYPES.SPA.abbr];
    }

    static validateType(val) {
        return LawyerType.getTypes().includes(val.toLowerCase());
    }

    static throwError(errorMessage) {
        throw `LawyerType: ${errorMessage}`;
    }
}
